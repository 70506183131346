<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.events") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td class="text-no-wrap">
                {{ row.item.name }}
              </td>
              <td>
                {{ row.item.activityType.name }}
              </td>
              <td>{{ row.item.owner.firstName }} {{ row.item.owner.lastName }}</td>
              <td>
                {{ row.item.online ? $t("hr.events.online") : $t("hr.events.activity") }}
              </td>
              <td>
                {{ row.item.beginDate | formattedDate }}
              </td>
              <td>
                {{ row.item.endDate | formattedDate }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description Performance Component Logic
   * @date 28.07.2020
   */

  import { EVENTS } from "../query";

  export default {
    name: "Events",
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD"),
          guest: vm.$store.state.auth.user.id
        },
        options: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "beginDate",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.events.name"),
            align: "left",
            sortable: true,
            value: "name",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.events.type"),
            align: "left",
            sortable: true,
            value: "activityType",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.events.owner"),
            align: "left",
            sortable: true,
            value: "owner.firstName",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.events.online"),
            align: "left",
            sortable: true,
            value: "online",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.events.begin_date"),
            align: "left",
            sortable: true,
            value: "beginDate",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.events.end_date"),
            align: "left",
            sortable: true,
            value: "endDate",
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: EVENTS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateActivities.content || [];
              this.table.totalItems = data.filterWithPaginateActivities.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
